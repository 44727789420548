import React, { useState, useRef, useEffect } from 'react';
import {
  Input,
  Button,
  Table,
  Tag,
  Card,
  Tooltip,
  Modal,
  Row,
  Col,
  message,
  DatePicker,
  Tabs,
  Checkbox,
  Spin,
  Result,
} from 'antd';
import { connect } from 'react-redux';
import {
  SearchOutlined,
  RocketOutlined,
  CreditCardOutlined,
  FileProtectOutlined,
  SolutionOutlined,
  EditFilled,
  EyeOutlined,
  ExclamationOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  CalendarOutlined,
  FormOutlined,
  PercentageOutlined,
  ReconciliationOutlined,
  RollbackOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ColumnsType } from 'antd/lib/table';
import { useWindowDimensions, useQuery } from '../../utils/hooks';
import '@ant-design/compatible/assets/index.css';
import { State, States } from 'sigt';
import moment, { Moment } from 'moment';
import '../../assets/css/components/TableProcedures.css';
import { useHistory } from 'react-router';
import DisplayDoc from '../DisplayDoc';
import TextArea from 'antd/lib/input/TextArea';
import handlingMessage from '../../utils/handlingMessage';
import {
  updateProcedure,
  setProcedure,
  changeProcedureData,
  changeProcedureStatus,
  changeProcedureCertificate,
} from '../../redux/actions/procedures';
import axios from 'axios';
import _, { remove } from 'lodash';
import TablePenalty from './TablePenalty';
import TableTaxes from './TableTaxes';
import TableBenefit from './TableBenefit';
import { createCertificate } from '../../services/procedures';
import { CargoCpu } from '../../routes';
import institutions from '../../redux/reducers/institutions';
import { render } from '@testing-library/react';
import { NONAME } from 'dns';
import TableReceipts from './TableReceipts';
import TableReceipt from './TableReceipt';
import TableBudget from './TableBudget';
import TableSolvencies from './TableSolvencies';
const server = process.env.REACT_APP_SERVER_URL;

const TableProcedure: React.FC<TableProcedureProps> = ({ updateProcedure, thm, auth, prcd, changeProcedureCertificate }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const inputRef = useRef<any>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [reverseState, setReverseState] = useState({});
  const [url, setUrl] = useState('');
  const [modalOptions, setModalOptions] = useState({});
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const queryParams = useQuery();
  const tipo = queryParams.get('tipo');
  const codigoTramite = queryParams.get('codigoTramite') ? queryParams.get('codigoTramite') : '';
  const [tab, setTab] = useState('tramites');
  const [keys, setKeys] = useState<Moment[]>([]);
  const [checked, setChecked] = useState<number[]>([]);
  const [updating, setUpdating] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [filterStatus, SetfilterStatus] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [isReverseModalVisible, setIsReverseModalVisible] = useState<boolean>(false);

  const liqueurPrcd = [29, 30, 31, 32, 33, 34, 35];

  enum UserType {
    SuperUser = 1,
    OfficialAdmin,
    Official,
    External,
    OfficialChief,
    DepartmentChief,
  }

  const isAdmin =
    (auth.user?.institucion?.cargo?.id === 24 &&
    auth.user?.tipoUsuario === UserType.OfficialAdmin &&
    auth.user?.institucion?.id === 9) || auth.user?.cuentaFuncionario?.id_cargo === 106;

  const StatusConfig: colorStatus = {
    iniciado: { color: 'default', name: 'Iniciado' },
    validando: { color: 'warning', name: 'Validando pago' },
    ingresardatos: { color: 'cyan', name: 'En espera de pago' },
    finalizado: { color: 'green', name: 'Finalizado' },
    enproceso: { color: 'geekblue', name: 'En proceso' },
    enrevision: { color: 'lime', name: 'En Revision' },
    enrevision_analista: { color: 'lime', name: 'En Revision Gerente' },
    enrevision_gerente: { color: 'lime', name: 'En Revision Gerente General' },
    porrevisar: { color: 'cyan', name: 'Por Revisar' },
    atendido: { color: 'warning', name: 'Atendido' },
    visto: { color: 'default', name: 'Visto' },
    aprobado: { color: 'green', name: 'Aprobado' },
    pagocajero: { color: 'cyan', name: 'Pago por caja' },
    negado: { color: 'error', name: 'Negado' },
    error: { color: 'error', name: 'Error' },
    inspeccion: { color: 'magenta', name: 'Inspección' },
    rechazado: { color: 'error', name: 'Rechazado' },
    denegado: { color: 'black', name: 'Denegado' },
    encorreccion: { color: 'yellow', name: 'En Correccion' },
  };

  useEffect(() => {
    if (tipo && tipo !== tab && (tipo === 'multas' || tipo === 'tramites' || tipo === 'beneficios' || tipo === 'liquidaciones')) setTab(tipo);
    else setTab('tramites');
    // eslint-disable-next-line
  }, [tipo]);

  useEffect(() => {
    if (auth.user?.institucion?.nombreCorto === 'PMM') setTab('multas');
  }, [auth.user]);

  const changeInspectionStatus = async (record: any, defaultState = 'inspeccion') => {
    const tramite = {
      estado: defaultState,
      tipoTramite: record.tipoTramite,
      idTramite: record.id,
      revision: {
        aprobado: false,
      },
    };
    handlingMessage({
      action: () => updateProcedure({ tramite, idUsuario: auth.user?.id }, auth.token || ''),
      key: 'aprobar',
      loadingMessage: 'Realizando operación...',
      cb: () => {
        setModalVisible(false);
        setLoading(false);
      },
    });
  };

  const reverseProcedureHandler = (record, bool) => {
    setReverseState(record);
    setIsReverseModalVisible(bool);
  };

  const createCodCat = async (record: any) => {
    try {
      const _record = Object.assign({}, record);
      _record.datos.funcionario.areaTerreno = _record.datos?.funcionario?.areaConstruccion;
      _record.datos.funcionario.codCat = `${_record.datos.funcionario.codCat}`.toUpperCase();
      setLoading(true);
      const res =
        record.nombreTramiteCorto === 'CC'
          ? await axios.post(
              `${process.env.REACT_APP_SERVER_URL}/estates`,
              { tramite: _record },
              {
                headers: { Authorization: `Bearer ${auth.token}` },
              }
            )
          : { status: 200 };
      if (res.status === 200) {
        if (record.nombreTramiteCorto === 'CC')
          message.info('Registro de codigo catastral para el inmueble realizado exitosamente');
        const tramite = {
          estado: 'enrevision',
          tipoTramite: record.tipoTramite,
          idTramite: record.id,
          revision: {
            aprobado: true,
          },
        };
        handlingMessage({
          action: () => updateProcedure({ tramite, idUsuario: auth.user?.id }, auth.token || ''),
          key: 'aprobar',
          loadingMessage: 'Realizando operación...',
          cb: () => {
            setModalVisible(false);
            setLoading(false);
          },
        });
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      message.error('Error al realizar la operacion');
    }
  };

  useEffect(() => {
    if (prcd?.procedures) {
      let filterStatus: any[] = [];
      let _data: any[] = [];

      prcd.procedures.forEach((ele) => {
        let estado =
          ele.estado === 'finalizado' && !ele.certificado && liqueurPrcd.includes(ele.tipoTramite)
            ? StatusConfig['rechazado']
            : ( ele.estado === 'finalizado' && !ele.aprobado ? StatusConfig['rechazado'] : StatusConfig[ele.estado]);
        if (auth.user?.tipoUsuario === UserType.DepartmentChief) {
          if (
            _.includes(auth.user?.permisos, ele.tipoTramite) &&
            ele.estado !== 'enrevision_gerente' &&
            ele.estado !== 'validacion'
          ) {
            _data.push({ 
              ...ele, 
              estado,
              razonSocial: ele.datos?.usuario?.contribuyente?.razonSocial || ele.datos?.usuario?.razonSocial || 'N/A',
            });
            setData(_data);
          }
        } else {
          if (auth.user?.tipoUsuario === UserType.Official) {
            if (auth.user?.institucion?.cargo?.id === 46) {
              if (
                _.includes(auth.user?.permisos, ele.tipoTramite) &&
                (ele.estado === 'enproceso' ||
                  ele.estado === 'inspeccion' ||
                  ele.estado === 'encorreccion' ||
                  (ele.estado === 'finalizado' && ele.aprobado === true) ||
                  (ele.estado === 'finalizado' && auth.user?.institucion?.nombreCorto === 'SEDETEMA'))
              ) {
                _data.push({ 
                  ...ele, 
                  estado,
                  razonSocial: ele.datos?.usuario?.contribuyente?.razonSocial || ele.datos?.usuario?.razonSocial || 'N/A',
                });
                setData(_data);
              }
            } else {
              if (
                _.includes(auth.user?.permisos, ele.tipoTramite) &&
                (ele.estado === 'enproceso' ||
                  ele.estado === 'inspeccion' ||
                  ele.estado === 'encorreccion' ||
                  (ele.estado === 'finalizado' && auth.user?.institucion?.nombreCorto === 'SEDETEMA'))
              ) {
                _data.push({ 
                  ...ele, 
                  estado,
                  razonSocial: ele.datos?.usuario?.contribuyente?.razonSocial || ele.datos?.usuario?.razonSocial || 'N/A',
                });
                setData(_data);
              }
            }
          } else {
            if (auth.user?.tipoUsuario === UserType.Official) {
              if (
                _.includes(auth.user?.permisos, ele.tipoTramite) &&
                (ele.estado === 'enproceso' ||
                  ele.estado === 'inspeccion' ||
                  ele.estado === 'encorreccion' ||
                  (ele.estado === 'finalizado' && auth.user?.institucion?.nombreCorto === 'SEDETEMA'))
              ) {
                _data.push({ 
                  ...ele, 
                  estado,
                  razonSocial: ele.datos?.usuario?.contribuyente?.razonSocial || ele.datos?.usuario?.razonSocial || 'N/A',
                });
                setData(_data);
              }
            } else {
              if (typeof _.find(filterStatus, (e) => e.value === estado.name) !== 'object')
                filterStatus.push({ value: estado.name, text: estado.name });
              _data.push({
                ...ele,
                estado,
                razonSocial: ele.datos?.usuario?.contribuyente?.razonSocial || ele.datos?.usuario?.razonSocial || 'N/A',
              });
              setData(_data);
            }
          }
        }
        SetfilterStatus(filterStatus);
        if (_data.length > 0 && codigoTramite) {
          const id = parseInt(codigoTramite);
          const ele = data.find((el) => el.id === id);
          _data = _data.filter((el) => el.id !== id);
          _data.unshift(ele);
          setData(_data);
        }

        if (_data.length > 0 && auth.user?.institucion?.id === 9) {
          //filtro para beneficios y pagos por cajero de CPU
          _data = _data?.filter((e) => 
          ![26].includes(e.tipoTramite) || 
          e.estado === 'pagocajero');
          //filtro de consignacion de documentos
          if (![27, 23].includes(auth.user?.institucion?.cargo?.id)) _data = _data?.filter((e) => e.tipoTramite !== 38);
          //filtro de tramites licores
          if (![44, 45].includes(auth.user?.institucion?.cargo?.id))
            _data = _data?.filter((e) => !liqueurPrcd.includes(e.tipoTramite));
          setData(_data);
        }
      });
      // eslint-disable-next-line
    }
  }, [prcd.procedures]);

  const getFile = (data) => {
    setDisabled(true);
    const id = data.id;
    handlingMessage({
      action: () => createCertificate(id, auth.token || ''),
      key: 'file',
      loadingMessage: 'Realizando operación...',
      cb: (data) => {
        if (data.status === 200) {
          const win = window.open(data.url, '_blank');
          win?.focus();
          changeProcedureCertificate(id, data.url);
        }
        setDisabled(false);
      },
    });
  };

  const renderAction = (record) => {
    let certificadoProps =
      record.estado.name === StatusConfig.finalizado.name && record.certificado
        ? { style: { backgroundColor: '#87d068', borderColor: '#87d068' }, target: '_blank', href: record.certificado }
        : { onClick: () => getFile(record) };
    if (auth.user?.institucion?.id !== 0 && record.tipoTramite !== 0) {
      if (
        auth.user?.tipoUsuario === UserType.External &&
        record.estado.name !== StatusConfig.finalizado.name &&
        record.estado.name !== StatusConfig.ingresardatos.name &&
        record.estado.name !== StatusConfig.encorreccion.name &&
        record.planilla
      ) {
        return (
          <Tooltip title='Solicitud'>
            <Button
              target='_blank'
              href={url}
              shape='round'
              type='primary'
              icon={<SolutionOutlined />}
              onClick={() => setUrl(record.planilla)}
              size='small'
            >
              {width > 440 ? 'Solicitud' : null}
            </Button>
          </Tooltip>
        );
      } else if (
        auth.user?.tipoUsuario === UserType.External &&
        record.estado.name === StatusConfig.encorreccion.name &&
        record.nombreCorto === 'CPU'
      ) {
        //let ins = institutions?.filter((i) => i.nombreCorto === 'CPU')

        return (
          <Tooltip title='En espera de correcciones'>
            <Button
              target='_blank'
              shape='round'
              type='primary'
              icon={<SolutionOutlined />}
              onClick={() => history.push(`/dashboard/tramite/3/${record.tipoTramite}/${record.id}`)}
              size='small'
            >
              {width > 440 ? 'Corregir' : null}
            </Button>
          </Tooltip>
        );
      } else if (record.estado.name === StatusConfig.finalizado.name) {
        return (
          // auth?.user?.tipoUsuario !== 4 && 
          // <Tooltip title='Certificado'>
          //   <Button
          //     {...certificadoProps}
          //     disabled={disabled}
          //     shape='round'
          //     type={record.certificado ? 'primary' : 'default'}
          //     icon={<FileProtectOutlined />}
          //     size='small'
          //   >
          //     {width > 440 ? 'Certificado' : null}
          //   </Button>
          // </Tooltip>
          null
        );
      } else if (
        (auth.user?.tipoUsuario === UserType.Official || auth?.user?.institucion?.cargo?.id === 24 || 
          auth.user?.institucion?.cargo?.id === 90 || auth.user?.institucion?.cargo?.id === 98 || auth.user?.institucion?.cargo?.id === 56 ) &&
        record.estado.name === StatusConfig.enproceso.name &&
        record.tipoTramite !== 38
      ) {
        return (
          <Tooltip title='Procesar'>
            <Button
              shape='round'
              type='danger'
              icon={<RocketOutlined />}
              onClick={() => history.push(`/dashboard/procesarTramite/${record.id}`)}
            >
              {width > 440 ? 'Procesar' : null}
            </Button>
          </Tooltip>
        );
      } else if (auth.user?.tipoUsuario === UserType.DepartmentChief && record.estado.name === StatusConfig.enrevision.name) {
        return (
          <Tooltip title='Aprobar'>
            <Button
              shape='round'
              type='danger'
              icon={<RocketOutlined />}
              onClick={() => handleVerification(record, 'enrevision')}
            >
              {width > 440 ? 'Aprobar' : null}
            </Button>
          </Tooltip>
        );
      } else if (
        auth.user?.tipoUsuario === UserType.OfficialChief &&
        record.estado.name === StatusConfig.enrevision_gerente.name
      ) {
        return (
          <Tooltip title='Aprobar'>
            <Button
              shape='round'
              type='danger'
              icon={<RocketOutlined />}
              onClick={() => handleVerification(record, 'enrevision_gerente')}
            >
              {width > 440 ? 'Aprobar' : null}
            </Button>
          </Tooltip>
        );
      } else if (
        auth.user?.tipoUsuario === UserType.Official &&
        record.estado.name === StatusConfig.enproceso.name &&
        record.tipoTramite === 38
      ) {
        return (
          <Tooltip title='Procesar'>
            <Button
              shape='round'
              type='danger'
              icon={<RocketOutlined />}
              onClick={() => history.push(`/dashboard/inmuebles/documentos/ver/${record.id}`)}
            >
              {width > 440 ? 'Procesar' : null}
            </Button>
          </Tooltip>
        );
      } else if (record.estado.name === StatusConfig.ingresardatos.name && auth.user?.tipoUsuario === UserType.External) {
        return record.estado.name === StatusConfig.ingresardatos.name ? (
          <Tooltip title='Pagar'>
            <Button
              shape='round'
              type='primary'
              className='ant-btn-procedure'
              icon={<CreditCardOutlined />}
              size='small'
              onClick={() => history.push(`/dashboard/pagarTramite/${record.id}`)}
            >
              {width > 440 ? 'Pagar' : null}
            </Button>
          </Tooltip>
        ) : null;
      } else if (
        record.estado.name === StatusConfig.enrevision.name &&
        auth.user?.institucion?.cargo?.id === 45 &&
        liqueurPrcd.includes(record.tipoTramite)
      ) {
        return (
          <Tooltip title='Verificar'>
            <Button
              shape='round'
              type='primary'
              icon={<EyeOutlined />}
              size='small'
              onClick={() => handleVerification(record, undefined)}
            >
              {width > 440 ? 'Verificar' : null}
            </Button>
          </Tooltip>
        );
      } else if (
        auth.user?.institucion?.cargo?.id === 18 &&
        record.estado.name === StatusConfig.finalizado.name &&
        record.nombreCorto === 'CPU'
      ) {
        //let ins = institutions?.filter((i) => i.nombreCorto === 'CPU')

        return (
          <Tooltip title='Reversar Trámite'>
            <Button
              shape='round'
              type='danger'
              icon={<ExclamationCircleOutlined />}
              onClick={() => reverseProcedureHandler(record, true)}
            >
              {width > 440 ? 'Reversar' : null}
            </Button>
          </Tooltip>
        );
      } else if (
        record.estado.name === StatusConfig.enrevision.name &&
        auth.user?.tipoUsuario === UserType.OfficialChief &&
        auth.user?.institucion?.id !== 9 &&
        auth.user?.institucion?.id !== 3
      ) {
        return (
          <Tooltip title='Verificar'>
            <Button shape='round' type='primary' icon={<EyeOutlined />} size='small' onClick={() => handleVerification(record)}>
              {width > 440 ? 'Verificar' : null}
            </Button>
          </Tooltip>
        );
      } else if (
        record.estado.name === StatusConfig.enrevision.name &&
        auth.user?.tipoUsuario === UserType.OfficialAdmin &&
        auth.user?.institucion?.id === 9 &&
        !liqueurPrcd.includes(record.tipoTramite)
      ) {
        return (
          <Tooltip title='Verificar'>
            <Button
              shape='round'
              type='primary'
              icon={<EyeOutlined />}
              size='small'
              onClick={() => history.push(`/dashboard/procesarTramite/${record.id}`)}
            >
              {width > 440 ? 'Verificar' : null}
            </Button>
          </Tooltip>
        );
      } else if (record.estado.name === StatusConfig.inspeccion.name && auth.user?.institucion?.cargo?.id === 44) {
        return (
          <Tooltip title='Procesar Inspeccion'>
            <Button
              shape='round'
              type='primary'
              icon={<EyeOutlined />}
              size='small'
              onClick={() => history.push(`/dashboard/procesarTramiteInspeccion/${record.id}`)}
            >
              {width > 440 ? 'Procesar' : null}
            </Button>
          </Tooltip>
        );
      } else if (
        record.estado.name === StatusConfig.inspeccion.name &&
        (auth.user?.institucion?.cargo?.id === 46 ||
          auth.user?.institucion?.cargo?.id === 93 ||
          auth.user?.institucion?.cargo?.id === 95)
      ) {
        return (
          <Tooltip title='Procesar Inspeccion'>
            <Button shape='round' type='primary' icon={<EyeOutlined />} onClick={() => changeInspectionStatus(record)}>
              {width > 440 ? 'Procesar' : null}
            </Button>
          </Tooltip>
        );
      }
    } else {
      //casos sociales
      const admin = auth.user?.tipoUsuario === UserType.OfficialAdmin;
      return (
        <Tooltip title='Modificar'>
          <Button
            shape='round'
            type='primary'
            icon={admin ? <EditFilled /> : <EyeOutlined />}
            onClick={() => history.push(`/dashboard/casosSociales/${record.id}`)}
          >
            {width > 440 ? (admin ? 'Modificar' : 'Ver') : null}
          </Button>
        </Tooltip>
      );
    }
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return (
        <Row style={{ width: 250, padding: 8 }} gutter={[8, 8]}>
          <Col span={24}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format='DD/MM/YYYY'
              placeholder={['Fecha inicio ', 'Fecha fin']}
              size='small'
              onChange={(values) => {
                setSelectedKeys(values);
              }}
              value={selectedKeys}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<SearchOutlined />}
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Buscar
            </Button>
          </Col>
          <Col span={12}>
            <Button style={{ width: '100%' }} onClick={() => clearFilters()} size='small'>
              Restablecer
            </Button>
          </Col>
        </Row>
      );
    },
    filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => {
      return (
        moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') ||
        moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
      );
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleVerification = (record, state?, nextEvent?) => {
    const options = {
      title: 'Revisión',
      content: () => (
        <DisplayDoc
          expand
          url={`${process.env.REACT_APP_SERVER_URL}/procedures/mockCertificate/${record.id}`}
          content={() => (
            <Row justify='end' style={{ margin: '8px 0' }} gutter={16}>
              <Col>
                <Button
                  icon={<CloseOutlined />}
                  type='danger'
                  loading={loading}
                  onClick={() =>
                    Modal.confirm({
                      title: liqueurPrcd.includes(record.tipoTramite)
                        ? '¿Esta seguro de rechazar el tramite?'
                        : '¿Esta seguro de regresar el documento al funcionario?',
                      icon: <ExclamationCircleOutlined />,
                      content: <TextArea ref={inputRef} placeholder='Observaciones' />,
                      okText: 'Denegar',
                      okType: 'danger',
                      cancelText: 'Cancelar',
                      onOk() {
                        let observaciones = '';
                        if (inputRef.current) {
                          observaciones = inputRef.current?.state?.value;
                        }
                        setLoading(true);

                        const data = {
                          tramite: {
                            estado: state || 'enrevision',
                            idTramite: record.id,
                            tipoTramite: record.tipoTramite,
                            revision:
                              state === 'enrevision_gerente'
                                ? { aprobado: false, observaciones }
                                : { aprobado: false, observaciones },
                          },
                          idUsuario: auth.user?.id,
                        };
                        handlingMessage({
                          action: () => updateProcedure(data, auth.token || ''),
                          key: 'denegar',
                          loadingMessage: 'Realizando operación...',
                          cb: () => {
                            setLoading(false);
                            setModalVisible(false);
                          },
                        });
                      },
                    })
                  }
                >
                  Denegar
                </Button>
              </Col>
              {liqueurPrcd.includes(record.tipoTramite) &&
                (auth.user?.institucion?.cargo?.id === 24 || auth.user?.institucion?.cargo?.id === 35) && (
                  <>
                    <Col>
                      <Button
                        icon={<RollbackOutlined />}
                        type='default'
                        loading={loading}
                        onClick={() =>
                          Modal.confirm({
                            title: '¿Esta seguro de regresar el documento al funcionario?',
                            icon: <ExclamationCircleOutlined />,
                            content: <TextArea ref={inputRef} placeholder='Observaciones' />,
                            okText: 'Denegar',
                            okType: 'danger',
                            cancelText: 'Cancelar',
                            onOk() {
                              let observaciones = '';
                              if (inputRef.current) {
                                observaciones = inputRef.current?.state?.value;
                              }
                              setLoading(true);
                              const data = {
                                tramite: {
                                  estado: 'enrevision',
                                  idTramite: record.id,
                                  tipoTramite: record.tipoTramite,
                                  revision: { aprobado: false, observaciones },
                                  rebotado: true,
                                },
                                idUsuario: auth.user?.id,
                              };
                              handlingMessage({
                                action: () => updateProcedure(data, auth.token || ''),
                                key: 'denegar',
                                loadingMessage: 'Realizando operación...',
                                cb: () => {
                                  setLoading(false);
                                  setModalVisible(false);
                                },
                              });
                            },
                          })
                        }
                      >
                        Rebotar
                      </Button>
                    </Col>
                  </>
                )}
              <Col>
                {state ? (
                  <Button
                    icon={<CheckOutlined />}
                    loading={loading}
                    type='primary'
                    onClick={() => {
                      let observaciones = '';
                      if (inputRef.current) {
                        observaciones = inputRef.current?.state?.value;
                      }
                      setLoading(true);

                      const data = {
                        tramite: {
                          estado: state,
                          idTramite: record.id,
                          tipoTramite: record.tipoTramite,
                          revision:
                            state === 'enrevision_gerente'
                              ? { aprobado: true, observaciones }
                              : { aprobado: true, observaciones },
                        },
                        idUsuario: auth.user?.id,
                      };
                      handlingMessage({
                        action: () => updateProcedure(data, auth.token || ''),
                        key: 'aprobar',
                        loadingMessage: 'Realizando operación...',
                        cb: () => {
                          setLoading(false);
                          setModalVisible(false);
                        },
                      });
                    }}
                  >
                    Aprobar
                  </Button>
                ) : (
                  <Button
                    icon={<CheckOutlined />}
                    loading={loading}
                    type='primary'
                    onClick={() =>
                      auth.user?.institucion?.cargo?.id === 18 && changeInspectionStatus(record, 'enrevision_gerente')
                    }
                  >
                    Aprobar
                  </Button>
                )}
              </Col>
            </Row>
          )}
        />
      ),
      width: 800,
      footer: null,
    };
    setModalVisible(true);
    setModalOptions(options);
  };

  const reverseProcedureStateHandler = async (record) => {
    console.log(record);
    setLoading(true);
    const tramite = {
      estado: 'finalizado',
      tipoTramite: record.tipoTramite,
      idTramite: record.id,
      revision: {
        aprobado: false,
      },
    };
    console.log(tramite);
    handlingMessage({
      action: () => updateProcedure({ tramite, idUsuario: auth.user?.id }, auth.token || ''),
      key: 'reversar',
      loadingMessage: 'Realizando operación...',
      cb: () => {
        setIsReverseModalVisible(false);
        setLoading(false);
      },
    });
  };

  const approveSelected = async () => {
    setUpdating(true);
    try {
      const response = await axios.post(
        `${server}/procedures/license/approve`,
        { tramites: checked },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      const ts = response.data.tramites;
      message.success('Trámite(s) actualizado(s) de manera exitosa.');
      setData((data) =>
        data.map((d) => ({
          ...d,
          estado: checked.includes(d.id) ? StatusConfig.finalizado : d.estado,
          certificado: checked.includes(d.id) ? ts.find((t) => t.id === d.id)?.certificado : d.certificado,
        }))
      );
      setChecked([]);
    } catch (e) {
      message.error(e.response.data.message || e.response.data || 'Error al aprobar trámites.');
    } finally {
      setUpdating(false);
    }
  };

  const renderModal = (options) => {
    const { content: Content, ...props } = options;

    return Content ? (
      <Modal centered maskClosable onCancel={() => setModalVisible(false)} {...props} visible={modalVisible}>
        <Content />
      </Modal>
    ) : null;
  };

  const showActionsCol =
    auth.user?.tipoUsuario === UserType.Official ||
    auth.user?.institucion?.cargo?.id === 90 ||
    auth.user?.institucion?.cargo?.id === 98 ||
    auth.user?.institucion?.cargo?.id === 56 ||
    auth.user?.tipoUsuario === UserType.External ||
    auth.user?.tipoUsuario === UserType.DepartmentChief ||
    data.find((e) => e.estado.name === StatusConfig.finalizado.name || e.estado.name === StatusConfig.enrevision.name) ||
    (auth.user?.tipoUsuario === UserType.OfficialAdmin && auth.user?.institucion?.id === 0);

  const columns: ColumnsType<any> = [
    //if inst === alcaldia. (esto para casos sociales)

    auth.user?.institucion?.id !== 0
      ? {
          title: 'Codigo',
          dataIndex: 'codigoTramite',
          align: 'center',
          ...getColumnSearchProps('codigoTramite'),
        }
      : {
          title: 'Cedula',
          dataIndex: 'datos',
          align: 'center',
          ...getColumnSearchProps('codigoTramite'),
          render: (text, record) => `${record.datos.nacionalidadSolicitante} - ${text.cedula}`,
        },
    {
      title: 'Razon Social',
      dataIndex: 'razonSocial',
      align: 'center',
      ...getColumnSearchProps('razonSocial'),
      render: (text, record) => <Tooltip title={text}>{text}</Tooltip>,
    },
    auth.user?.institucion?.id !== 0
      ? {
          title: 'Trámite',
          dataIndex: 'nombreTramiteCorto',
          align: 'center',
          ...getColumnSearchProps('nombreTramiteCorto'),
          render: (text, record) => <Tooltip title={record.nombreTramiteLargo}>{text}</Tooltip>,
        }
      : {
          title: 'Nombre',
          dataIndex: 'datos',
          align: 'center',
          ...getColumnSearchProps('codigoTramite'),
          render: (text) => text.nombreCompleto,
        },
    //only show when user is superuser or external
    auth.user?.tipoUsuario === 1 || auth.user?.tipoUsuario === 4
      ? {
          title: 'Institución',
          dataIndex: 'nombreCorto',
          align: 'center',
          ...getColumnSearchProps('nombreCorto'),
          render: (text, record) => <Tooltip title={"SAT"}>{"SAT"}</Tooltip>,
        }
      : { width: 0 },
    {
      title: 'Fecha',
      dataIndex: 'fechaCreacion',
      align: 'center',
      ...getColumnFilterDate('fechaCreacion'),
      render: (text, record) => {
        let time = '';
        let now = new Date();
        if (record.datosUltimoEditor) {
          time = record.datosUltimoEditor.fecha_movimiento;
          now = new Date(time);
          let hours = now.getHours() + 4;
          now.setHours(hours);
        }
        return time !== '' && record.estado.name !== 'Finalizado' ? (
          <Tooltip
            title={`Tiempo desde el ultimo cambio de estado: 
            ${moment(now).fromNow()}`}
          >
            {moment(text).format('DD/MM/YYYY')}
          </Tooltip>
        ) : (
          <Tooltip title='Fecha de Creacion'>{moment(text).format('DD/MM/YYYY')}</Tooltip>
        );
      },
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      align: 'center',
      filters: filterStatus,
      onFilter: (value, record) => record.estado.name?.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
      render: (value, record) => {
        return record.datosUltimoEditor?.nombre_completo && auth.user?.tipoUsuario !== 4 ? (
          <Tooltip title={`${record.datosUltimoEditor.nombre_completo}`}>
            <Tag style={{ width: '100%', textAlign: 'center' }} color={value.color}>
              {value.name}
            </Tag>
          </Tooltip>
        ) : (
          <Tag style={{ width: '100%', textAlign: 'center' }} color={value.color}>
            {value.name}
          </Tag>
        );
      },
    },
  ];

  const isAllowedUser =
    auth.user?.institucion?.cargo?.id === 18 ||
    auth.user?.institucion?.cargo?.id === 5 ||
    auth.user?.institucion?.cargo?.id === 93 ||
    auth.user?.institucion?.cargo?.id === 95 ||
    auth.user?.institucion?.cargo?.id === 46 ||
    auth.user?.institucion?.cargo?.id === 96 ||
    auth.user?.institucion?.cargo?.id === 94 ||
    auth.user?.institucion?.cargo?.id === 47;

  if (isAllowedUser) {
    columns.unshift({
      title: '',
      align: 'center',
      width: 1,
      render: (text, record) => {
        if (record.datosUltimoEditor) {
          let difAsDays = moment
            .duration(moment(new Date()).diff(moment(record.datosUltimoEditor.fecha_movimiento)))
            .asDays()
            .toFixed(0);

          const haveMoreThanHalfDay: boolean = parseFloat(difAsDays) > 0.5 && record.estado !== 'finalizado';

          if (haveMoreThanHalfDay) {
            return (
              <Tooltip title='El tramite lleva mas de medio dia en el mismo estado.'>
                <WarningOutlined style={{ color: 'red' }} />
              </Tooltip>
            );
          } else return null;
        } else {
          return null;
        }
      },
    });
  }

  if (isAdmin)
    columns.unshift({
      title: () => (
        <Checkbox
          onChange={(e) =>
            e.target.checked
              ? setChecked(
                  prcd.procedures
                    .filter((e) => e.estado === 'enrevision' && (e.tipoTramite === 28 || e.tipoTramite === 36))
                    .map((e) => e.id)
                )
              : setChecked([])
          }
        />
      ),
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <Checkbox
          onChange={(e) => setChecked((c) => (e.target.checked ? [...c, id] : remove(c, (t) => t !== id)))}
          checked={checked.includes(id)}
          disabled={record.estado.name !== StatusConfig.enrevision.name && record.tipoTramite !== 28 && record.tipoTramite !== 36}
        />
      ),
    });

  const selectedRow = (record) => {
    const id = parseInt(codigoTramite ? codigoTramite : '');
    return record.id === id ? 'filterRow' : '';
  };

  if (showActionsCol)
    columns.push({
      title: '',
      dataIndex: 'acciones',
      fixed: 'right',
      align: 'center',
      width: 50,
      render: (text, record) => renderAction(record),
    });
  const cardTitle = {
    multas: 'Multas',
    tramites: 'Solicitudes de trámites',
    impuestos: 'Bandeja de Impuestos',
    beneficios: 'Bandeja de Beneficios',
    solvencias: 'Solvencias'
  };
  const indexR = columns.findIndex((ele) => ele.dataIndex === 'razonSocial');
  if (auth.user?.institucion?.id !== 9) delete columns[indexR];
  return (
    <Card
      style={{ height: '100%' }}
      title={cardTitle[tab]}
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }}
      bodyStyle={{ overflowY: 'auto', height: '90%' }}
    >
      <Modal
        visible={isReverseModalVisible}
        okType='danger'
        onCancel={() => setIsReverseModalVisible(false)}
        onOk={() => reverseProcedureStateHandler(reverseState)}
      >
        <Result
          title='Advertencia!'
          subTitle={<p>Si procede el trámite se reversará y tendrá que ser procesado nuevamente</p>}
          status='warning'
        />
      </Modal>
      <Tabs defaultActiveKey='tramites' onChange={setTab} activeKey={tab}>
        {auth.user?.institucion?.nombreCorto !== 'PMM' && (
          <Tabs.TabPane
            key='tramites'
            tab={
              <span>
                <FormOutlined />
                Trámites
              </span>
            }
          >
            {renderModal(modalOptions)}
            <Table
              rowClassName={(record) => selectedRow(record)}
              pagination={{ pageSize: isAdmin ? 9 : 10 }}
              size='middle'
              columns={columns}
              dataSource={data}
              rowKey={(e) => e.id}
            />
            {isAdmin && (
              <Row>
                <Col span={24} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Button disabled={checked.length === 0} type='primary' onClick={approveSelected}>
                    Aprobar trámites seleccionados
                  </Button>
                </Col>
              </Row>
            )}
            <Modal closable={false} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }} visible={updating}>
              <Row>
                <Col span={24} style={{ justifyContent: 'center', display: 'flex' }}>
                  <Spin
                    tip='Actualizando trámites...'
                    indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
                  />
                </Col>
              </Row>
            </Modal>
          </Tabs.TabPane>
        )}
        {/* {auth.user?.tipoUsuario === UserType.External ||
        auth.user?.institucion?.nombreCorto === 'IMA' ||
        auth.user?.institucion?.nombreCorto === 'PMM' ? (
          <Tabs.TabPane
            key='multas'
            tab={
              <span>
                <ExclamationCircleOutlined />
                Multas
              </span>
            }
          >
            <TablePenalty />
          </Tabs.TabPane>
        ) : null} */}
        {auth.user?.tipoUsuario === UserType.External ? (
          <Tabs.TabPane
            key='impuestos'
            tab={
              <span>
                <PercentageOutlined />
                Impuestos
              </span>
            }
          >
            <TableTaxes />
          </Tabs.TabPane>
        ) : null}
        {auth.user?.tipoUsuario === UserType.OfficialAdmin || auth.user?.cuentaFuncionario?.id_cargo === 106 || auth.user?.tipoUsuario === UserType.OfficialChief || auth.user?.tipoUsuario === UserType.External ? (
          <Tabs.TabPane
            key='liquidaciones'
            tab={
              <span>
                <PercentageOutlined />
                Comprobantes de Pagos
              </span>
            }
          >
            <TableReceipts />
          </Tabs.TabPane>
        ) : null}
        {auth.user?.institucion?.nombreCorto === 'SEDEMAT' &&
        (auth.user?.tipoUsuario === UserType.OfficialAdmin || [56,90,98,103,104].includes(auth.user?.institucion?.cargo?.id)) ? (
          <Tabs.TabPane
            key='beneficios'
            tab={
              <span>
                <ReconciliationOutlined />
                Beneficios fiscales
              </span>
            }
          >
            <TableBenefit />
          </Tabs.TabPane>
        ) : null}
        {auth.user?.tipoUsuario === UserType.External ? (
          <Tabs.TabPane
            key='solvencias'
            tab={
              <span>
                <PercentageOutlined />
                Solvencias
              </span>
            }
          >
            <TableSolvencies />
          </Tabs.TabPane>
        ) : null}
      </Tabs>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, prcd: state.prcd });

export default connect(mapStateToProps, {
  updateProcedure,
  setProcedure,
  changeProcedureData,
  changeProcedureStatus,
  changeProcedureCertificate,
})(TableProcedure);

interface TableProcedureProps {
  updateProcedure: (data: any, token: string) => Promise<void>;
  thm: States.ThemeColors;
  auth: States.Auth;
  prcd: States.Procedures;
  changeProcedureCertificate: (id: number, certificate: string) => void;
}

interface colorStatus {
  [status: string]: {
    name: StatusProcedure;
    color: string;
  };
}

declare type StatusProcedure =
  | 'Validando pago'
  | 'Rechazado'
  | 'En espera de pago'
  | 'Pago por caja'
  | 'En Revision'
  | 'En Revision Gerente'
  | 'En Revision Gerente General'
  | 'Finalizado'
  | 'En proceso'
  | 'Iniciado'
  | 'Por Revisar'
  | 'Atendido'
  | 'Visto'
  | 'Aprobado'
  | 'Negado'
  | 'Error'
  | 'Inspección'
  | 'En Correccion'
  | 'Denegado';
