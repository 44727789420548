import React, { useEffect, useState, useRef } from "react";
import { SearchOutlined, FileDoneOutlined, CalendarOutlined } from '@ant-design/icons';
import { Table, Tooltip, Button, Card, Row, Input, Col, DatePicker, Tag } from "antd";
import { connect } from "react-redux";
import { State, States } from 'sigt';
import { useWindowDimensions } from "../../utils/hooks";
import Highlighter from "react-highlight-words"
import { ColumnsType } from 'antd/lib/table';
import "../../assets/css/components/TableOfficial.css";
import moment, { Moment } from "moment";
import axios from "axios";
import _ from "lodash";
const server = process.env.REACT_APP_SERVER_URL;

const TableSolvencies: React.FC<PropsTable> = ({ thm, auth }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();
  const [data, setData] =  useState<DataArray[]>([])
  const [visible, setVisible] = useState(false);
  const [keys, setKeys] = useState<Moment[]>([]);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
    return(
      <Row style={{width:250, padding: 8}} gutter={[8,8]}>
        <Col span={24}>
          <DatePicker.RangePicker style={{width:'100%'}} format='DD/MM/YYYY' placeholder={['Fecha inicio ','Fecha fin']} size='small' 
            onChange={(values)=>{ setSelectedKeys(values) }} value={selectedKeys} 
          />
        </Col>
        <Col span={12}>
          <Button style={{width:'100%'}} type='primary' icon={<SearchOutlined />} size='small' onClick={() => { confirm(); }}>
            Buscar
          </Button>
        </Col>
        <Col span={12} >
          <Button style={{width:'100%'}} onClick={() => clearFilters()} size='small'>
            Restablecer
          </Button>
        </Col>
      </Row> 
    )},
    filterIcon: filtered => (
      <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => {
      return moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') 
        || moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
    },  
  });

  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if(dataIndex === "consumido")
      setSearchText(selectedKeys[0]);
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };


  const columns: ColumnsType<any> = [
    {
      dataIndex: "tipo_solvencia",
      title: "Tipo Solvencia",
      key: "tipo_solvencia",
    },
    {
      dataIndex: "actual",
      title: "Más reciente",
      key: "actual",
      render: (value, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.actual).color}>{getState(record.actual).name}</Tag>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      align: 'center',
      ...getColumnFilterDate('fecha'),
      render: (text, record) => <>{moment.utc(text).format('DD/MM/YYYY')}</>
    },
    {
      title: 'Fecha Vencimiento',
      dataIndex: 'fecha_vencimiento',
      align: 'center',
      ...getColumnFilterDate('fecha_vencimiento'),
      render: (text, record) => <>{moment.utc(text).format('DD/MM/YYYY')}</>
    },
    {
      title: "",
      key: "action",
      render: (_,record) => (<Tooltip title='Solvencia' visible={visible}>
      <Button
        shape='round' 
        target='_blank' 
        href={record.url || ''} 
        icon={<FileDoneOutlined />} 
        onMouseOver={() => width > 440 ? setVisible(false) : setVisible(true)} 
        onMouseLeave={()=> setVisible(false)}
        size='small'>
        {width > 440 ? 'Solvencia' : null}
      </Button>
    </Tooltip>)
    }
  ] 

  const getState = (actual) => {
    switch (actual) {
      case true:
        return { name: 'Más reciente', color: 'green' };       
      default:
        return { name: 'Anterior', color: 'warning' };
    }
  };

  useEffect(() => {
    aux();
    // eslint-disable-next-line
  }, []);

  const aux = async() => {
    if(auth.token) {
      try {
        let dat : any[] = [];
        if (auth?.user?.tipoUsuario === 4) {
          const response = await axios.get(
            `${server}/receipt/solvencies/${auth?.user?.contribuyente?.id}`,
            { headers: { Authorization: `Bearer ${auth.token}` } }
          );
          response.data.solvencia.forEach(ele => {
            console.log(ele)
            const state = getState(ele.actual)
            dat.push({...ele , state })
          })
        }
        console.log(dat, "is dat")
        setData(dat)
      } catch (e) {
        throw e;
      }
    }
    setLoading(false);
  }

    // useEffect(() => {
    //   setLoading(data.length > 0 ? false : true)
    // }, [data]);
    
  return (
    <Card style={{ height: '100%' }} title='Solvencias' 
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: "#fff" }} bodyStyle={{ overflowY: 'auto' }}>
      <Table
        style={{ height: '100%' }}
        size='middle'
        loading={loading}
        pagination={{ pageSize: 7 }}
        columns={columns}
        dataSource={data}
      />
    </Card>
  );
};
const mapStateToProps = ({ auth, thm }: State) => ({ auth, thm });

export default connect( mapStateToProps, { })(TableSolvencies);

interface PropsTable {
  auth: States.Auth;
  thm: States.ThemeColors;
}

interface DataArray {
  id: number;
  fecha: string;
  recibo: string;
  razonSocial: string;
  rim: string;
  nombre_completo:string;
}
